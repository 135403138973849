import moment from 'moment'
import React, { useEffect, useState } from 'react'
import ReactPaginate from 'react-paginate'
import { useMediaQuery } from 'react-responsive'
import { formatCurrency, formatCurrencyNoVnd } from '../../utils'
import http from '../../utils/http'
import ExportCSV from '../ExportCSV/ExportCSV'
import Filter from '../Filter/Filter'
import Header from '../Header/Header'

const ITEMS_PER_PAGE = 7

const Transaction = () => {
  const isMobile = useMediaQuery({ maxWidth: 767 })

  const [currentPage, setCurrentPage] = useState(0)
  const [currentItems, setCurrentItems] = useState([])
  const [pageCount, setPageCount] = useState(0)
  const [itemOffset, setItemOffset] = useState(0)
  const [listKiosk, setListKiosk] = useState([])
  const [transactions, setTransactions] = useState([])

  const [filteredTrans, setFilteredTrans] = useState([])

  const getSettings = async () => {
    try {
      const kiosktotal = sessionStorage.getItem('kioskTotal');
  
      if (kiosktotal) {
        const kiosks = [];
  
        for (let i = kiosktotal - 1; i >= 0; i--) {
          const kioskname = sessionStorage.getItem('kioskName' + i);
          const kioskId = sessionStorage.getItem('kioskId' + i);

          if (kioskname) {
            const kiosk = { kioskId: kioskId, kioskName: kioskname };
            kiosks.push(kiosk);
          }
        }
  
        setListKiosk(kiosks);
      }
  
      sessionStorage.setItem('transload', '0');
    } catch (error) {
      console.log('error: ', error);
    }
  };

  useEffect(() => {
    getSettings()
  }, [])

  const [filterTransaction, setFilterTransaction] = useState({
    licensePlate: '',
    paymentMethod: '',
    kioskId: '',
    startDate: null,
    endDate: null
  })

  const handleChangeFilterTransaction = (value) => {
    setFilterTransaction({
      ...filterTransaction,
      ...value
    })
  }

  const handleResetFilter = () => {
    setFilterTransaction({
      licensePlate: '',
      paymentMethod: '',
      kioskId: '',
      startDate: null,
      endDate: null
    })
  }

  const totalTransactions = transactions.length

  const handlePageClick = (event) => {
    const newOffset = (event.selected * ITEMS_PER_PAGE) % totalTransactions
    setItemOffset(newOffset)
    setCurrentPage(event.selected)
  }

  useEffect(() => {
    const endOffset = itemOffset + ITEMS_PER_PAGE
    console.log("filter transaction ",filterTransaction)
    const filteredTransactions = transactions
      .filter((transaction) => {
        if (filterTransaction.startDate) {
          return moment(transaction.checkInTime).isSameOrAfter(
            moment(new Date(filterTransaction.startDate).getTime()),
            'day'
          )
        }
        return true
      })
      .filter((transaction) => {
        if (filterTransaction.endDate) {
          return moment(transaction.checkOutTime).isSameOrBefore(
            moment(new Date(filterTransaction.endDate).getTime()),
            'day'
          )
        }
        return true
      })
      .filter((transaction) => {
        if (filterTransaction.kioskId) {
          return transaction.kioskId === filterTransaction.kioskId
        }
        return true
      })
      .filter((transaction) => {
        if (filterTransaction.licensePlate) {
          return transaction.licensePlate === filterTransaction.licensePlate
        }
        return true
      })
      .filter((transaction) => {
        if (filterTransaction.paymentMethod) {
          return transaction.paymentMethod === filterTransaction.paymentMethod
        }
        return true
      })

    setFilteredTrans(filteredTransactions)
    setCurrentItems(filteredTransactions.slice(itemOffset, endOffset))

    setPageCount(Math.ceil(filteredTransactions.length / ITEMS_PER_PAGE))
  }, [transactions, itemOffset, filterTransaction])

  useEffect(() => {
    setCurrentPage(0)
    setItemOffset(0)
  }, [filterTransaction])

  const getTransactions = async (kioskid) => {
    try {
      const transArr = []
      const resp = await http.post('/transactions', { kioskId: kioskid })
      if (resp.data.length > 0) {
        resp.data.forEach((element) => {
          transArr.push(element)
        })
      }
      transArr.reverse();
      setTransactions(transArr)
      console.log(transactions)
    } catch (error) {
      console.log('error', error)
    }
  }

  useEffect(() => {
    ;(async () => {
      try {
        const kiosktotal = sessionStorage.getItem('kioskTotal')
        const transloadpost = sessionStorage.getItem('transloadpost')

        if (transloadpost == null && transloadpost !== '0') {
          sessionStorage.setItem('transloadpost', '0')
          console.log('Kiosk total ',kiosktotal); 
          for (var i = 0; i < kiosktotal; i++) {
            const kioskid = sessionStorage.getItem('kioskId' + i)
            console.log('Get transaction from id',kioskid)
            getTransactions(kioskid)
          }
        }
      } catch (error) {
        console.log('error: ', error)
      }
    })()
  }, [])

  useEffect(() => {
    if (transactions.length !== 0) {
      sessionStorage.removeItem('transload')
      sessionStorage.removeItem('transloadpost')
    }
  }, [transactions])

  const renderPaymentMethod = (paymentMethod) => {
    if (paymentMethod === 'NONE'){
      return (
        <span className="badge py-3 px-4 fs-7 badge badge-dark">NONE</span>
      )
    }else if (paymentMethod === 'FREE'){
      return (
        <span className="badge py-3 px-4 fs-7 badge badge-success">FREE</span>
      )
    }else if (paymentMethod === 'MOMO') {
      return (
        <span className="badge py-3 px-4 fs-7 badge-light-danger">MOMO</span>
      )
    } else if (paymentMethod === 'VNPAY') {
      return (
        <span className="badge py-3 px-4 fs-7 badge-light-primary">VNPAY</span>
      )
    } else if (paymentMethod === 'MPOS') {
      return (
        <span className="badge py-3 px-4 fs-7 badge-light-warning">MPOS</span>
      )
    } else if (paymentMethod === 'ONEPAY') {
      return (
        <span className="badge py-3 px-4 fs-7 badge-light-warning">ONEPAY</span>
      )
    } else {
      return (
        <span className="m-3 badge py-3 px-4 fs-7 badge-light-primary">
          CASH
        </span>
      )
    }
  }

  const renderTakeOutBy = (takeOutBy) => {
    if(takeOutBy === 'GUEST' ){
      return (
        <span className="badge py-3 px-4 fs-7 badge-light-success">GUEST</span>
      )
    }
    if(takeOutBy && takeOutBy.startsWith('ADMIN')){
      return (
        <span className="badge py-3 px-4 fs-7 badge-light-primary">{takeOutBy}</span>
      )    
    }
  }

  const transactionDataExport =
    filteredTrans &&
    filteredTrans.length > 0 &&
    filteredTrans.map((transaction, index) => ({
      id: index + 1,
      licensePlate: transaction?.licensePlate,
      paymentAmount: formatCurrencyNoVnd(transaction?.paymentAmount),
      paymentMethod:
        transaction?.paymentMethod === 'CASH'
          ? 'Tiền mặt'
          : transaction?.paymentMethod,
      kioskId: transaction?.kioskId,
      checkInTime : transaction?.checkInTime?moment(transaction?.checkInTime).format('DD/MM/YYYY HH:mm:ss'):"",
      checkOutTime : transaction?.checkOutTime?moment(transaction?.checkOutTime).format('DD/MM/YYYY HH:mm:ss'):"",
      updatedAt : transaction?.updatedAt?moment(transaction?.updatedAt).format('DD/MM/YYYY HH:mm:ss'):"",
      updateByRole : transaction?.updateByRole
    }))

  const wscols =
    transactionDataExport && transactionDataExport.length > 0
      ? [
          {
            wch: Math.max(
              ...transactionDataExport.map(
                (transaction) => transaction.id.length
              )
            )
          },
          {
            wch:
              Math.max(
                ...transactionDataExport.map(
                  (transaction) => transaction.licensePlate.length
                )
              ) + 5
          },
          {
            wch:
              Math.max(
                ...transactionDataExport.map(
                  (transaction) => transaction.paymentAmount.length
                )
              ) + 5
          },
          {
            wch:
              Math.max(
                ...transactionDataExport.map(
                  (transaction) => transaction.paymentMethod.length
                )
              ) + 15
          },
          {
            wch:
              Math.max(
                ...transactionDataExport.map(
                  (transaction) => transaction.kioskId.length
                )
              ) + 10
          },
          {
            wch:
              Math.max(
                ...transactionDataExport.map(
                  (transaction) => transaction.checkInTime.length
                )
              ) + 7
          }
          ,
          {
            wch:
              Math.max(
                ...transactionDataExport.map(
                  (transaction) => transaction.checkOutTime.length
                )
              ) + 7  
          },
          {
            wch:
              Math.max(
                ...transactionDataExport.map(
                  (transaction) => transaction.updatedAt.length
                )
              ) + 7  
          }
          ,
          {
            wch:
              Math.max(
                ...transactionDataExport.map(
                  (transaction) => transaction.updateByRole
                )
              ) + 5
          }
        ]
      : []

  return (
    <div
      className="content d-flex flex-column flex-column-fluid"
      id="kt_content"
    >
      {isMobile && <Header />}
      <div
        style={isMobile ? { marginTop: '55px' } : {}}
        className="post d-flex flex-column-fluid"
        id="kt_post"
      >
        <div id="kt_content_container" className="container-xxl">
          <div className="row gy-5 g-xl-8">
            <div className="col-xl-12">
              <div className="card card-xl-stretch mb-5 mb-xl-8">
                <div className="card-header border-0 pt-5">
                  <div className="w-100 card-title d-flex align-items-center justify-content-between">
                    <span className="card-label fw-bolder fs-3">GIAO DỊCH</span>
                    <Filter
                      listKiosk={listKiosk}
                      filterTransaction={filterTransaction}
                      onResetFilter={handleResetFilter}
                      onChangeFilterTransaction={handleChangeFilterTransaction}
                    />
                    <ExportCSV
                      csvData={transactionDataExport}
                      fileName={`Danh_sach_giao_dich_xuat_${moment().format(
                        'DD_MM_YYYY'
                      )}`}
                      wscols={wscols}
                      headerTitle={[
                        'id',
                        'licensePlate',
                        'paymentAmount',
                        'paymentMethod',
                        'kioskId',
                        'checkInTime',
                        'checkOutTime',
                        'updatedAt',
                        'updateByRole',
                      ]}
                      headerTranslate={[
                        {
                          id: 'STT',
                          licensePlate: 'Số ô',
                          paymentAmount: 'Số tiền giao dịch',
                          paymentMethod: 'Phương thức thanh toán',
                          kioskId: 'Tên máy Kiosk',
                          checkInTime : 'Thời gian gửi',
                          checkOutTime : 'Thời gian tạo lệnh TT',
                          updatedAt : "Thời gian lấy",
                          updateByRole : 'Người mở tủ'
                        }
                      ]}
                    />
                  </div>
                </div>

                <div className="card-body py-3">
                  <div className="table-responsive">
                    <table className="table-transaction table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4">
                      <thead>
                        <tr className="fw-bolder text-muted">
                          <th className="min-w-100px text-center">STT</th>
                          <th className="min-w-100px text-center">
                            Số Máy
                          </th>
                          <th className="min-w-100px text-center">
                            Số Ô
                          </th>
                          <th className="min-w-150px text-center">Thời gian gửi</th>
                          <th className="min-w-150px text-center">Thời gian tạo lệnh TT</th>
                          <th className="min-w-150px text-center">Thời gian Lấy</th>

                          <th className="min-w-150px text-center">Số tiền</th>
                          <th className="min-w-150px text-center">
                            HT thanh toán
                          </th>
                          <th className="min-w-150px text-center">Người mở tủ</th>
                        </tr>
                      </thead>
                      <tbody>
                        {currentItems.map((transaction, index) => (
                          <tr>
                            <td className="text-center">
                              <span className="text-dark d-block fs-6">
                                {itemOffset + index + 1}
                              </span>
                            </td>
                            <td className="text-center">
                              {
                                listKiosk.find(
                                  (kiosk) =>
                                    kiosk.kioskId === transaction.kioskId
                                )?.kioskName
                              }
                            </td>
                            <td className="text-center">
                              <div className="d-flex align-items-center justify-content-center">
                                <span className="mx-3 fw-bold text-dark d-block fs-6">
                                  {transaction.licensePlate}
                                </span>
                              </div>
                            </td>
                            <td className="text-center">
                              <span className="text-dark d-block fs-6">
                                {
                                transaction.checkInTime? moment(transaction.checkInTime).format(
                                  'HH:mm:ss'
                                ) : ""}
                              </span>
                              <br />
                              <span className="text-dark d-block fs-6">
                                {transaction.checkInTime? moment(transaction.checkInTime).format(
                                  'DD/MM/YYYY'
                                ) : ""}
                              </span>
                            </td>
                            <td className="text-center">
                              <span className="text-dark d-block fs-6">
                                {
                                transaction.checkOutTime? moment(transaction.checkOutTime).format(
                                  'HH:mm:ss'
                                ) : ""}
                              </span>
                              <br />
                              <span className="text-dark d-block fs-6">
                                {transaction.checkOutTime? moment(transaction.checkOutTime).format(
                                  'DD/MM/YYYY'
                                ) : ""}
                              </span>
                            </td>
                            <td className="text-center">
                              <span className="text-dark d-block fs-6">
                                {
                                transaction.updatedAt? moment(transaction.updatedAt).format(
                                  'HH:mm:ss'
                                ) : ""}
                              </span>
                              <br />
                              <span className="text-dark d-block fs-6">
                                {transaction.updatedAt? moment(transaction.updatedAt).format(
                                  'DD/MM/YYYY'
                                ) : ""}
                              </span>
                            </td>
                            <td className="text-center">
                              <span className="text-dark d-block fs-6">
                                {formatCurrency(transaction.paymentAmount)}
                              </span>
                            </td>
                            <td className="text-center">
                              {renderPaymentMethod(transaction.paymentMethod)}
                            </td>
                           
                            <td className="text-center">
                              {renderTakeOutBy(transaction.updateByRole)}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <ReactPaginate
        nextLabel=">"
        onPageChange={handlePageClick}
        pageRangeDisplayed={3}
        marginPagesDisplayed={2}
        pageCount={pageCount}
        previousLabel="<"
        pageClassName="page-item"
        pageLinkClassName="page-link"
        previousClassName="page-item"
        previousLinkClassName="page-link"
        nextClassName="page-item"
        nextLinkClassName="page-link"
        breakLabel="..."
        breakClassName="page-item"
        breakLinkClassName="page-link"
        containerClassName="pagination"
        activeClassName="active"
        renderOnZeroPageCount={null}
        forcePage={currentPage}
      />
    </div>
  )
}

export default Transaction
